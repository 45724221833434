import {createRouter,createWebHashHistory} from 'vue-router'

const routers = [
    {
        path: '/home',
        name: 'home',
        component: () => import('./components/HelloWorld') 
    },
    {
        path: '/',
        component: () => import("./pages/game/index.vue")
    },
    {
        path:"/game",
        component: () =>import("./pages/game/index.vue")
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes:routers
})
export default router