import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/js/rem'
// 加载Vant核心组件库
import Vant from 'vant'
// 加载Vant全局样式
import 'vant/lib/index.css'

// import VueRouter from 'vue-router'
import routers from './router'
import { Dialog } from 'vant';
import { Toast } from 'vant';
import i18n from './language/index'
import store from './store'
import Vuex from 'vuex'
import Base64 from 'js-base64'
import VueFullscreen from 'vue-fullscreen'

// const router = new VueRouter({
//     mode: 'history',
//     routes: routers
// })



const app = createApp(App).use(store);
app.use(Vant)
app.use(routers)
app.use(Dialog)
app.use(i18n)
app.use(Vuex)
app.use(Base64)
app.use(VueFullscreen)
app.use(Toast);

app.config.globalProperties.$gameType = 3
app.config.globalProperties.$difficulty = 1

app.mount('#app')

// 局部注册
export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
};
