export default {
    game:{
        startGame: "Start",
        rankListTitle:"Ranking List",
        scoreTitle:"Score Report",
        currentScore: "My record/ranking:",
        bestScore: "My best record/ranking:",
        loading:"loading",
        ready: {
            cameraBody: "确保摄像头可以看到您的全身",
            body: "请将您的身体移动到方框内",
            halfBody: "请将您的上半身移动到方框里"
        },
        mycountry: {
            first: "My contry ",
            second: "ranking:"
        },
        mycity: {
            first: "My city ",
            second: "ranking:"
        },
        myrank: {
            first: "In",
            second: "my ranking:"
        },
        jumpContinue: {
            first: "You just need to eliminate ",
            second: "more to advance",
            third: "places."
        },
        joinNotice:"Which team do you want to join?",
        teamA: "Team A",
        teamB: "Team B",
        teamC: "Team C",
        teamD: "Team D",
        stakeError:"Stake error",
        gameOverEnd: "End the game",
        gameOverRestart: "Restart",
        gameOverRe:"To Resurrect",
        gameoverChallenginfo: "Challenge Info:",
        gameoverDifficult: "Difficult",
        gameoverType: "Type of Challenge",
        gameoverPool: "Prize Pool",
        gameoverParticipants: "Participants",
        gameoverWin: "Win Condition",
        gameoverStaking: "Type of Staking",
        gameoverResurrection: "Resurrection",
        gameoverWinC: {
            w1: "BRONZE(>=top_value_50)",
            w2: "SILVER(>=top_value_40)",
            w3: "GOLD(>=top_value_30)",
            w4: "PERKIN(>=top_value_20)",
            w5: "DIAMOND(>=top_value_10)",
            w6: "MASTER(>=top_value_5)",
            w7: "GRANDMASTER(>=top_value_3)",
            w8: "CHALLENGER(>=top_value_1)"
        },
        gameoverChallengType: {
            c1: "Entire network",
            c2: "In Challenge room"
        },
        gameoverStakType: {
            s1: "KGT",
            s2: "Gold score"
        },
        gameoverResurrectedTime:{
            r1:"no limitaion",
            r2: "none",
            r3:"time"
        },
        gameoverPPool: "Prize pool",
        gameoverNumberWinners: "Number of winners",
        gameoverTotalPart: "Total participants",
        gameoverAccept: "Accept Challenge",
        teachContinue: "Continue",
        loading: "Loading",
        mulitNoSocre:"Sorry, you do not have enough GOLD score to participate this challenge.",
        multiNoScoreBtn: "Go to earn Gold score",
        gameoverFuhuoContent:"Sorry, you do not have enough resurrection times to resurrection.",
        gameoverFuhuoContentBtn: "Go to get resurrection",
        gameoverFuhuoError:"Resurrection failed",
        gameNotice: "Make sure the camera can see your whole body",
        gameContinue: "CONTINUE"
        
    }
};