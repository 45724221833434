const baseSize = 32
// 设置rem函数
function setRem () {
    // 当前页面宽度相对于750宽的缩放比例,可根据自己需要修改
    var scale = document.documentElement.clientWidth / 750
    // if (window.orientation != 0 && window.orientation!=180){
    //     scale = document.documentElement.clientHeight / 750
    // }
    console.log(scale)

    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
    // window.location.reload()
}



// function setRem(){
//     let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//     let htmlDom = document.getElementsByTagName("html")[0]
//     if(htmlWidth>=450){
//         htmlDom.style.fontSize = 22+"px"
//     }else{
//         htmlDom.style.fontSize = htmlWidth/20+"px"
//     }
// }

// 初始化
setRem()

//  改变窗口大小时重新设置rem
window.onresize = function () {
    setRem()
    window.location.reload()
}