import Vuex from 'vuex'

export default new Vuex.Store({
  state: {
    token: "",
    single:false,
    roomId:""
  },
  mutations: {
    setToken (state, token){
      state.token = token.token
      state.single = token.single
      state.roomId = token.roomId
    }
  },
  actions: {

  }
})