<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <router-view />
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      fullscreen:true
    }
  },
  mounted () {

    // this.init();

  },
  methods: {

    init () {
      this.forceLandscapeScreenHandle();
      // 这里监控
      this.onWindowSizeChanged();
    },

    forceLandscapeScreenHandle () {
      const body = document.getElementsByTagName('body')[0];
      const html = document.getElementsByTagName('html')[0];
      const width = window.innerWidth;
      const height = window.innerHeight;
      const max = width > height ? width : height;
      const min = width > height ? height : width;
      body.style.width = max + "px";
      body.style.height = min + "px";
    },

    onWindowSizeChanged () {
      window.addEventListener("resize", this.forceLandscapeScreenHandle)
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

}

/* @media screen and (orientation: portrait) {
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
} */
</style>
