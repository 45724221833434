export default {
    game: {
        startGame:"开始游戏",
        rankListTitle: "排行榜",
        scoreTitle: "得分",
        currentScore:"当前成绩/排名:",
        bestScore:"最好成绩/排名:",
        loading: "加载中",
        ready:{
            cameraBody:"确保摄像头可以看到您的全身",
            body:"请将您的身体移动到方框内",
            halfBody: "请将您的上半身移动到方框里"
        },
        mycountry:{
            first:"我的国家",
            second:"排名:"
        },
        mycity: {
            first: "我的城市",
            second: "排名:"
        },
        myrank: {
            first: "我在",
            second: "中的排名:"
        },
        jumpContinue:{
            first:"如果再消",
            second:"将达到第",
            third:"名。"
        },
        joinNotice: "请选择一个队伍加入",
        teamA:"队伍A",
        teamB: "队伍B",
        teamC: "队伍C",
        teamD: "队伍D",
        stakeError: "质押失败",
        gameOverEnd:"结束游戏",
        gameOverRestart:"重新开始",
        gameOverRe: "复活",
        gameoverChallenginfo:"房间信息",
        gameoverDifficult: "难度",
        gameoverType: "挑战赛类型",
        gameoverPool: "奖池",
        gameoverParticipants: "参与人数",
        gameoverWin: "胜利条件",
        gameoverStaking: "质押类型",
        gameoverResurrection: "复活次数",
        gameoverWinC: {
            w1:"青铜(前50%)",
            w2: "白银(前40%)",
            w3: "黄金(前30%)",
            w4: "铂金(前20%)",
            w5: "钻石(前10%)",
            w6: "大师(前5%)",
            w7: "宗师(前3%)",
            w8: "最强王者(前1%)"
        },
        gameoverChallengType:{
            c1:"全网挑战赛",
            c2:"房间挑战赛"
        },
        gameoverStakType: {
            s1: "KGT",
            s2: "金币积分"
        },
        gameoverResurrectedTime: {
            r1: "无限制",
            r2: "0",
            r3: "次"
        },
        gameoverPPool: "参与人数",
        gameoverNumberWinners: "胜利条件",
        gameoverTotalPart: "质押类型",
        gameoverAccept:"接受挑战",
        teachContinue:"继续",
        loading:"加载中",
        mulitNoSocre: "对不起，您没有足够的金币积分来参加此挑战。",
        multiNoScoreBtn:"去获取",
        gameoverFuhuoContent: "对不起，没有足够的复活点",
        gameoverFuhuoContentBtn: "去获取",
        gameoverFuhuoError: "复活失败",
        gameNotice:"确保摄像头可以看到您的全身",
        gameContinue:"继续"

    }
    
};